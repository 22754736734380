// External Packages
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

// Internal Components
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import DetailPair from "../UI/DetailPair";
import Heading from "../UI/Heading";
import { requestAdminAPI } from "../../api/posh-api";
import {
  timestampToDate,
  timestampToString,
} from "../../util/timestamp-to-date";
import { differenceInHours, format } from "date-fns";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function EmployeeInfo() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [employee, setEmployee] = useState();
  const [month, setMonth] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchEmployee = async () => {
    try {
      setLoading(true);
      const response = await requestAdminAPI("fetch-employee", {
        employeeId: id,
      });
      setGraphData(response);
      setEmployee(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const setGraphData = (employee) => {
    setLoading(true);
    const d = [];
    let start;
    let totalHours = 0;
    employee?.logs?.forEach((log) => {
      if (timestampToDate(log.date).getMonth() !== month.getMonth()) return;

      if (log.checkin) {
        start = timestampToDate(log.date);
        // If less than 30 minutes late to work, consider it as on-time
        if (start.getMinutes() < 30) start.setMinutes(0);
      } else {
        const end = timestampToDate(log.date);
        // If more than 29 minutes, round up to the next hour
        if (end.getMinutes() > 29) {
          end.setHours(end.getHours() + 1);
          end.setMinutes(0);
        }
        if (start?.getDay() === end?.getDay()) {
          const hours = differenceInHours(end, start);
          totalHours = Number(Number(totalHours) + Number(hours)).toFixed(2);
          const date = format(start, "MM/dd");
          if (d.find((d) => d.date === date)) {
            const index = d.findIndex((d) => d.date === date);
            d[index].hours = Number(
              Number(d[index].hours) + Number(hours)
            ).toFixed(2);
            d[index].end = format(end, "hh:mm a");
            d[index].day = format(end, "EEEE");
          } else {
            d.push({
              date,
              hours,
              start: format(start, "hh:mm a"),
              end: format(end, "hh:mm a"),
              day: format(end, "EEEE"),
            });
          }
        }
      }
    });
    setData(d);
    setEmployee({ ...employee, totalHours });
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  useEffect(() => {
    setGraphData(employee);
  }, [month]);

  if (!id)
    return (
      <div className="w-full h-full p-8 rounded-lg bg-stone-900">
        <p className="text-stone-400">Employee ID Not Provided!</p>
      </div>
    );

  if (!loading && !employee)
    return (
      <div className="w-full h-full p-8 rounded-lg bg-stone-900">
        <p className="text-stone-400">Employee Record Not Found!</p>
      </div>
    );

  return (
    <div className="container">
      <Heading title="Employee Details" />

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="grid gap-4">
          <div className="info">
            <DetailPair label="Employee ID" value={employee.id} />
            <DetailPair label="Name" value={employee.name} />
            <DetailPair
              label={`${format(month, "MMMM")} Hours`}
              value={employee.totalHours}
            />
          </div>
          <Heading title="Hours" isBackArrow={false}>
            <select
              className="cursor-pointer input"
              onChange={(e) => setMonth(new Date(e.target.value))}
              defaultValue={month.toDateString()}
            >
              {(() => {
                const currentDate = new Date();
                const startYear = 2024;
                const startMonthIndex = 9; // October (0-based index)
                const options = [];
                let year = startYear;
                let month = startMonthIndex;

                while (
                  year < currentDate.getFullYear() ||
                  (year === currentDate.getFullYear() &&
                    month <= currentDate.getMonth())
                ) {
                  options.push(new Date(year, month, 1));
                  month++;
                  if (month > 11) {
                    month = 0;
                    year++;
                  }
                }

                return options.map((month, index) => (
                  <option key={index} value={month.toDateString()}>
                    {month.toLocaleString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </option>
                ));
              })()}
            </select>
          </Heading>
          <div className="w-full my-4 md:my-8 h-52 md:h-72">
            <ResponsiveContainer width="100%" height="100%">
              {!data.length ? (
                <div className="flex items-center justify-center h-full">
                  <p>No data available</p>
                </div>
              ) : (
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="date" />
                  <YAxis tick={{ fontSize: 12 }} />
                  <Tooltip
                    content={({ active, payload }) =>
                      active && payload && payload.length ? (
                        <div className="p-4 bg-white rounded-lg text-stone-900">
                          <p>{`Hours: ${payload[0]?.value}`}</p>
                          <p>{`Day: ${payload[0]?.payload?.day}`}</p>
                          <p>{`Start: ${payload[0]?.payload?.start}`}</p>
                          <p>{`End: ${payload[0]?.payload?.end}`}</p>
                        </div>
                      ) : null
                    }
                  />
                  <Bar dataKey="hours" stackId="b" fill="#ca8a04" />
                </BarChart>
              )}
            </ResponsiveContainer>
          </div>
          <div className="list max-h-[400px]">
            {employee?.logs?.map((log) => {
              if (timestampToDate(log.date) >= month)
                return (
                  <div className="grid grid-cols-2 text-sm pill justify-items-center">
                    <p>{log.checkin ? "Check In" : "Check Out"}</p>
                    <p>
                      {timestampToString(log.date, "EEEE do 'of' MMMM, yyyy 'at' hh:mm a")}
                    </p>
                  </div>
                );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
