import { useContext, useState } from "react";
import { AuthContext } from "../context/auth-context";

import PoshLogo from "./UI/PoshLogo";

export default function LoginPage() {
  const { login, error } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const formHandler = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    if (email.includes("poshcars.io")) {
      login(email, password);
    } else {
      window.location.href = "https://poshcars.io";
    }
  };

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="grid justify-center gap-8 p-8 mx-4 rounded-lg shadow-lg bg-stone-900 w-96">
        <div className="flex items-center mx-auto space-x-4">
          <PoshLogo />
          <p className="text-3xl font-extralight text-stone-400">Admin</p>
        </div>
        <form onSubmit={formHandler} className="grid gap-4">
          <div>
            <label className="text-stone-400">Email: </label>
            <input className="w-full form-input" name="email" type="email" required/>
          </div>
          <div>
            <label className="text-stone-400">Password: </label>
            <input
              className="w-full form-input"
              name="password"
              type={showPassword ? "text" : "password"}
              required
            />
            <p
              onClick={() => setShowPassword(!showPassword)}
              className="mt-1 text-xs text-right cursor-pointer text-stone-400 hover:underline"
            >
              {showPassword ? <span>Hide Password</span> : <span>Show Password</span>}
            </p>
          </div>
          <div className="grid items-end h-16">
            {error && (
              <p className="text-sm text-center text-red-600">{error}</p>
            )}
            <button type="submit" className="w-full mt-1 btn-primary">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
