// External Packages
import { useState, Fragment, useEffect } from "react";

// Internal Packages
import { BsFilter } from "react-icons/bs";
import Truncate from "../../util/truncate";

export default function FilterMenu({ onSubmit, options }) {
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState();

  const submitHandler = (event) => {
    event.preventDefault();
    let response = {};
    options.forEach((option) => {
      response[option.label.toLowerCase()] = event.target[option.label].value;
    });
    onSubmit && onSubmit(response);
    setShow(false);
  };

  useEffect(() => {
    let filters = 0;
    options.forEach((option) => {
      if (option.selected !== option.options[0]) {
        filters++;
      }
    });
    if (filters) setFilters(`${filters}`);
    else setFilters();
  }, [options]);

  return (
    <Fragment>
      {show && (
        <div
          onClick={() => setShow(false)}
          className="absolute top-0 left-0 w-screen h-screen cursor-pointer bg-stone-900 z-1 opacity-20"
        ></div>
      )}
      <div className="relative cursor-pointer">
        <div onClick={() => setShow(!show)}>
          {filters && (
            <p className="absolute text-[10px] rounded-full bg-primary px-1">
              {filters}
            </p>
          )}
          <BsFilter
            className="text-2xl text-white hover:text-stone-400"
          />
        </div>
        {show && (
          <form
            onSubmit={submitHandler}
            className="absolute top-0 z-10 grid w-40 gap-4 p-4 text-xs text-left rounded-lg text-stone-200 right-8 bg-stone-700"
          >
            {options?.map((option, idx) => (
              <div key={idx} className="grid gap-1">
                <p className="font-semibold">{option.label}</p>
                <select
                  className="form-input"
                  name={option.label}
                  defaultValue={option.selected}
                >
                  {option.options.map((opt, idx) => (
                    <option key={idx} value={opt}>
                      {Truncate(opt, 20)}
                    </option>
                  ))}
                </select>
              </div>
            ))}

            <button type="submit" className="btn-primary">
              Apply
            </button>
          </form>
        )}
      </div>
    </Fragment>
  );
}
