// External Imports
import { useEffect, useState, Fragment } from "react";
import Heading from "../UI/Heading";
import { requestAdminAPI } from "../../api/posh-api";
import { MdRefresh } from "react-icons/md";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import DetailPair from "../UI/DetailPair";
import { toTitleCase } from "../../util/format-text";
import { BsPlay } from "react-icons/bs";

// Internal Imports

/** ========================================================================
 * * FBDCTesting Component
 * This component renders a page to test and display sql data.
 * ========================================================================== */

const collections = [
  "customers",
  "bookings",
  "vehicles",
  "payments",
  "makes",
  "locations",
  "plans",
  "partners",
  "chargings",
  
];

export default function FBDCTesting() {
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState();
  const [data, setData] = useState();
  const [preview, setPreview] = useState();

  /* ====================== FETCH ALL DATA  ====================== */
  const fetchAllData = async () => {
    try {
      setLoading(true);
      setWarning("");
      const data = [];
      for (const collection of collections) {
        const collectionData = await requestAdminAPI("fbdc-get", {
          collection: collection,
        });
        data.push({ collection, data: collectionData });
      }
      setData(data);
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      console.error(err);
      setLoading(false);
    }
  };

  /* ====================== SHOW DATA  ====================== */
  const showData = (collection) => {
    try {
      setLoading(true);
      setWarning("");
      const collectionData = data.find(
        (item) => item.collection === collection
      );
      setPreview(collectionData.data);
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      console.error(err);
      setLoading(false);
    }
  };

  /* ====================== SEARCH DATA ====================== */
  const searchData = () => {
    try {
      setLoading(true);
      setWarning("");
      const collection = document.getElementById("collection").value;
      const id = document.getElementById("id").value;
      const collectionData = data.find(
        (item) => item.collection === collection
      );
      const item = collectionData.data.find((item) => item.id === id);
      setPreview(item);
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      console.error(err);
      setLoading(false);
    }
  };

  /* ====================== DELETE DATA ====================== */
  const deleteData = async () => {
    try {
      setLoading(true);
      setWarning("");
      const collection = document.getElementById("collection").value;
      const id = document.getElementById("id").value;
      await requestAdminAPI("fbdc-delete", {
        collection: collection.slice(0, -1),
        id: id,
      });
      setPreview();
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      console.error(err);
      setLoading(false);
    }
  };

  /* ====================== SEARCH DATA ====================== */
  const migrate = async () => {
    try {
      setLoading(true);
      setWarning("");
      await requestAdminAPI("fbdc-migrate", {});
      fetchAllData();
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <div className="container">
      <Heading title="FBDC Testing" isBackArrow={false}>
        <MdRefresh className="icon" onClick={fetchAllData} />
        <BsPlay className="icon" onClick={migrate} />
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="grid gap-8">
          <div className="grid grid-cols-5 gap-4 text-sm">
            {data.map((collection) => (
              <div
                className="cursor-pointer hover:opacity-80"
                onClick={() => showData(collection.collection)}
                key={collection.collection}
              >
                <DetailPair
                  label={collection.collection.toUpperCase()}
                  value={collection.data?.length || 0}
                />
              </div>
            ))}
          </div>
          <div className="flex items-center space-x-4 rounded-md">
            <div className="flex items-center space-x-4">
              <select className="w-40 select bg-stone-900" id="collection">
                {collections.map((collection) => (
                  <option key={collection} value={collection}>
                    {toTitleCase(collection)}
                  </option>
                ))}
              </select>
              <input
                type="text"
                className="border input border-stone-400"
                id="id"
                placeholder="id"
              />
            </div>
            <button onClick={searchData} className="btn btn-primary">
              Search
            </button>
            <button onClick={deleteData} className="btn btn-warning">
              Delete
            </button>
          </div>
          <p className="warning">{warning}</p>
          {preview && (
            <textarea
              className="p-4 rounded-lg no-scrollbar"
              cols={5}
              rows={20}
              value={JSON.stringify(preview, null, 2)}
              onChange={() => {}}
            />
          )}
        </div>
      )}
    </div>
  );
}
