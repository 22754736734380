// External Imports
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MdRefresh } from "react-icons/md";
import { BsPlusLg, BsFilter } from "react-icons/bs";

// Internal Imports
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import Heading from "../UI/Heading";
import SearchBar from "../UI/SearchBar";
import FilterMenu from "../UI/FilterMenu";

/** ========================================================================
 * * Coupons Page
 * Renders a page, which displays all coupons.
 * ========================================================================== */

export default function CouponsPage() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Load Context
  const { fetchCoupons, coupons } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Default Filters
  const defaultFilter = {
    search: params.get("search") ?? "",
    reusable: params.get("reusable") ?? "All",
    exclusive: params.get("exclusive") ?? "All",
    used: params.get("used") ?? "All",
  };

  // Initialize States
  const [filteredCoupons, setFilteredCoupons] = useState(coupons);
  const [filter, setFilter] = useState({ ...defaultFilter });

  /* ======================= APPLY FILTER ======================= */
  const applyFilters = () => {
    let results = [...coupons];

    // Apply Options filter
    results = optionsFilter(results);
    if (filter.search) results = searchFilter(results);
    setFilteredCoupons([...(results ?? [])]);
    navigate(
      `/coupons?search=${filter.search ?? ""}&used=${
        filter.used ?? "All"
      }&exclusive=${filter.exclusive ?? "All"}&reusable=${
        filter.reusable ?? "All"
      }`,
      {},
      { replace: true }
    );
  };

  /* ======================= CLEAR ALL FILTER ======================= */
  const clearSearch = () => {
    setFilter({ used: "All", exclusive: "All", reusable: "All", search: "" });
  };

  // =================== FILTER OPTIONS ===================
  const filterOptions = [
    {
      label: "Reusable",
      options: ["All", "Non Reusable", "Reusable"],
      selected: filter.reusable,
    },
    {
      label: "Exclusive",
      options: ["All", "Non Exclusive", "Exclusive"],
      selected: filter.exclusive,
    },
    {
      label: "Used",
      options: ["All", "Used", "Not Used"],
      selected: filter.used,
    },
  ];

  /* ======================= FILTER BY OPTIONS ======================= */
  const optionsFilter = (data) => {
    let records = [...data];
    // Destructure filter properties for cleaner access
    const { reusable, exclusive, used } = filter;

    // Helper function to determine if a coupon matches the filter criteria
    const matchesFilter = (coupon) => {
      // Reusable Filter
      const isReusableMatch =
        reusable === "All" ||
        (reusable === "Reusable" && coupon.reusable) ||
        (reusable === "Non Reusable" && !coupon.reusable);
      // Exclusive Filter
      const isExclusiveMatch =
        exclusive === "All" ||
        (exclusive === "Exclusive" && coupon.forId) ||
        (exclusive === "Non Exclusive" && !coupon.forId);
      // Used Filter
      const isUsedMatch =
        used === "All" ||
        (used === "Used" && coupon.usedBy) ||
        (used === "Not Used" && !coupon.usedBy);

      return isReusableMatch && isExclusiveMatch && isUsedMatch;
    };

    // Filter the data array using the helper function
    return records.filter(matchesFilter);
  };

  // =================== FILTER BY SEARCH ===================
  const searchFilter = (records) => {
    return records.filter((coupon) => {
      const string = `${coupon.type} ${coupon.code} ${coupon.amount}`;
      let flag = true;
      // Loop over all words in query so that order is irrelevant
      filter.search?.split(" ").forEach((word) => {
        if (!string.includes(word.toLowerCase())) flag = false;
      });
      return flag;
    });
  };

  // Apply filters
  useEffect(() => {
    applyFilters();
  }, [filter]);

  return (
    <div className="container grid">
      <Heading title="Coupons" isBackArrow={false}>
        <FilterMenu
          onSubmit={(f) => setFilter({ ...filter, ...f })}
          options={filterOptions}
        />
        <BsPlusLg
          onClick={(e) => navigate("/coupons/add", e)}
          className="icon"
        />
        <MdRefresh onClick={fetchCoupons} className="text-2xl icon" />
      </Heading>

      <SearchBar
        searchHandler={(search) => setFilter({ ...filter, search })}
        clearSearch={clearSearch}
        searchValue={filter.search}
      />

      <div className="list">
        {filteredCoupons.map((c, idx) => (
          <div
            key={idx}
            onClick={(e) => navigate("/coupons/info?id=" + c.id, e)}
            className="grid grid-cols-5 text-sm pill text-stone-400"
          >
            <p className="mx-auto text-stone-200">{c.id}</p>
            <p>
              {c.amount < 1 ? `${Number(c.amount) * 100}%` : `$${c.amount}`}
            </p>
            <p>{c.reusable ? "Reusable" : "Not Reusable"}</p>
            <p>{c.forId ? "Exclusive" : "Not Exclusive"}</p>
            <p>{c.usedBy ? `${c.usedBy.length} uses` : "Not Used"}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
