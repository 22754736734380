// External Packages
import { useState, useEffect, useContext, Fragment, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

// Internal Packages
import { AuthContext } from "../../context/auth-context";
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { toTitleCase } from "../../util/toTitleCase";
import { timestampToString } from "../../util/timestamp-to-date";
import {
  checkAchStatus,
  deleteInvoice,
  fetchCustomerById,
  fetchPaymentById,
} from "../../api/fetch";
import {
  chargeInvoice,
  createStripeInvoice,
  payInvoice,
  sendMessage,
} from "../../api/add";
import { truncate } from "../../util/format-text";

// Internal Components
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import DetailPair from "../UI/DetailPair";
import LinkPair from "../UI/LinkPair";
import Heading from "../UI/Heading";
import { requestAdminAPI } from "../../api/posh-api";
import Notes from "../UI/Notes";

export default function PaymentInfo() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { user } = useContext(AuthContext);
  const { refreshData } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [payment, setPayment] = useState();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState();
  const [showPayInvoice, setShowPayInvoice] = useState(false);
  const [warning, setWarning] = useState();
  const [response, setResponse] = useState(false);

  // =================== FETCH PAYMENT DATA ===================
  const fetchPaymentData = useCallback(() => {
    setLoading(true);
    fetchPaymentById(user, id)
      .then((data) => {
        setPayment(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [user, id]);

  // =================== FETCH CUSTOMER ===================
  const fetchCustomerHandler = async () => {
    try {
      setLoading(true);
      setWarning();
      const customer = await fetchCustomerById(user, payment.customer.id);
      setCustomer(customer);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning("Error fetching customer");
      setLoading(false);
    }
  };

  // =================== CHARGE INVOICE ===================
  const chargeInvoiceHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const paymentMethodId = event.target.payment_method_id?.value;
      if (!paymentMethodId) throw new Error("No payment method selected");
      const charge = await chargeInvoice(user, {
        invoiceId: payment.id,
        paymentMethodId: paymentMethodId,
      });
      refreshData(() => {
        navigate("/payments");
        setCustomer();
        setLoading(false);
      });
    } catch (err) {
      setCustomer();
      setWarning(err.message);
      setLoading(false);
    }
  };

  // =================== MARK AS PAID HANDLER ===================
  const payInvoiceHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const source = event.target.source.value;
      await payInvoice(user, {
        invoiceId: payment.id,
        source: source,
      });
      refreshData(() => {
        navigate("/payments");
        setLoading(false);
      });
    } catch (err) {
      setWarning(err.message);
      setLoading(false);
    }
  };

  // =================== DELETE INVOICE ===================
  const deleteInvoiceHandler = async () => {
    try {
      setLoading(true);
      const response = await deleteInvoice(user, { paymentId: payment.id });
      refreshData(() => {
        navigate("/payments");
        setLoading(false);
      });
    } catch (err) {
      setWarning(err.message);
      console.log(err);
      setLoading(false);
    }
  };

  // =================== GENERATE RECEIPT ===================
  const receiptHandler = async () => {
    try {
      setLoading(true);
      const url = await requestAdminAPI("get-payment-receipt", {
        paymentId: payment.id,
      });
      window.open(url, "_blank");
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // =================== CREATE STRIPE INVOICE ===================
  const stripeInvoiceHandler = async () => {
    try {
      setLoading(true);
      const response = await createStripeInvoice(user, {
        invoiceId: payment.id,
      });
      window.open(response.link, "_blank");
      fetchPaymentData();
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      console.log(err);
      setLoading(false);
    }
  };

  // =================== SEND PAYMENT REMINDER ===================
  const sendReminderHandler = async () => {
    try {
      setLoading(true);
      const response = await requestAdminAPI("send-reminder", {
        paymentId: payment.id,
      });
      setResponse(response.message);
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      console.log(err);
      setLoading(false);
    }
  };

  // =================== CHECK ACH STATUS ===================
  const checkAchStatusHandler = async () => {
    try {
      setLoading(true);
      const response = await checkAchStatus(user, {
        paymentId: payment.id,
      });
      fetchPaymentData();
    } catch (err) {
      setWarning(err.message);
      console.log(err);
      setLoading(false);
    }
  };

  // =================== USE_EFFECT ===================
  useEffect(() => {
    fetchPaymentData();
  }, [fetchPaymentData]);

  // =================== RENDER DEFAULT MENU
  const defaultMenu = () => {
    const paid = payment.status === "succeeded";
    const invoice = payment.type === "invoice";
    return (
      <>
        {!paid && invoice && (
          <button
            onClick={() => setShowPayInvoice(true)}
            className="btn-secondary"
          >
            Mark as Paid
          </button>
        )}
        {invoice && (
          <button onClick={deleteInvoiceHandler} className="btn-secondary">
            Delete Invoice
          </button>
        )}
        {!paid && invoice && (
          <button onClick={fetchCustomerHandler} className="btn-secondary">
            Charge Customer
          </button>
        )}
        {invoice && !paid && !payment.stripe && (
          <button onClick={stripeInvoiceHandler} className="btn-secondary">
            Generate Payment Link
          </button>
        )}
        {invoice && !paid && (
          <button onClick={sendReminderHandler} className="btn-secondary">
            Send Reminder
          </button>
        )}
        <button onClick={receiptHandler} className="btn-secondary">
          Download PDF
        </button>
        {!invoice && payment.fca_id && !paid && (
          <button onClick={checkAchStatusHandler} className="btn-secondary">
            Check Status
          </button>
        )}
        {warning && <p className="text-red-500">{warning}</p>}
        {response && (
          <p className="response md:col-span-3">
            RESPONSE: {truncate(response, 60)}
          </p>
        )}
      </>
    );
  };

  // =================== RENDER CHARGE MENU
  const chargeCustomerMenu = () => {
    return (
      <form
        onSubmit={chargeInvoiceHandler}
        className="grid gap-6 md:grid-cols-3 md:col-span-3"
      >
        <button onClick={() => setCustomer()} className="btn-secondary">
          Cancel
        </button>
        {customer?.default_payment_method ? (
          <select className="form-input" name="payment_method_id">
            <option value={customer.default_payment_method.id}>
              {customer.default_payment_method.brand}{" "}
              {customer.default_payment_method.last4}
            </option>
            {customer.payment_methods &&
              customer.payment_methods.map((pm) => (
                <option value={pm.id}>
                  {pm.brand} {pm.last4}
                </option>
              ))}
          </select>
        ) : (
          <input
            className="form-input-disabled"
            placeholder="No Payment Methods Attached"
            disabled
          />
        )}
        <button type="submit" className="btn-primary">
          Confirm
        </button>
      </form>
    );
  };

  // =================== RENDER MARK AS PAID MENU
  const payInvoiceMenu = () => {
    return (
      <form
        onSubmit={payInvoiceHandler}
        className="grid gap-6 md:grid-cols-3 md:col-span-3"
      >
        <button
          onClick={() => setShowPayInvoice(false)}
          className="btn-secondary"
        >
          Cancel
        </button>
        <div className="grid">
          <input
            className="form-input"
            placeholder="Source e.g. Zelle, Cashapp etc"
            name="source"
            required
          />
        </div>
        <button type="submit" className="btn-primary">
          Confirm
        </button>
      </form>
    );
  };

  // =================== RENDER MENU
  const renderMenu = () => {
    if (customer) return chargeCustomerMenu();
    if (showPayInvoice) return payInvoiceMenu();
    else return defaultMenu();
  };

  if (!id)
    return (
      <div className="w-full h-full p-8 rounded-lg bg-stone-900">
        <p className="text-stone-400">Payment ID Not Provided!</p>
      </div>
    );

  if (!loading && !payment)
    return (
      <div className="w-full h-full p-8 rounded-lg bg-stone-900">
        <p className="text-stone-400">Payment Not Found!</p>
      </div>
    );

  return (
    <div className="container">
      <Heading title="Payment Details"></Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="info">
          <DetailPair label="Payment ID" value={payment.id} />
          <DetailPair label="Type" value={toTitleCase(payment.type)} />
          <DetailPair
            label="Date Created"
            value={timestampToString(
              payment.date_created,
              "eee dd MMM, yyyy - hh:mm a"
            )}
          />
          <DetailPair label="Status" value={toTitleCase(payment.status)} />

          {payment.type === "invoice" && (
            <Fragment>
              <DetailPair
                label="Due Date"
                value={`${
                  payment.due_date
                    ? timestampToString(
                        payment.due_date,
                        "eee dd MMM, yyyy - hh:mm a"
                      )
                    : "-"
                }`}
              />
              <DetailPair
                label="Date Paid"
                value={`${
                  payment.date_paid
                    ? timestampToString(
                        payment.date_paid,
                        "eee dd MMM, yyyy - hh:mm a"
                      )
                    : "-"
                }`}
              />
              <DetailPair
                label="Paid via"
                value={payment.source ? payment.source : payment.stripeId}
              />
            </Fragment>
          )}

          <DetailPair
            label="Customer ID"
            value={payment.customer.id}
            link={`/customers/info?id=${payment.customer.id}`}
          />
          <DetailPair
            label="Customer"
            value={payment.customer.firstName + " " + payment.customer.lastName}
            link={`/customers/info?id=${payment.customer.id}`}
          />

          <DetailPair
            label="Booking ID"
            value={payment.booking?.id || "-"}
            link={`/bookings/info?id=${payment.booking?.id}` || ""}
          />
          {payment.stripe && (
            <LinkPair
              label="Stripe Payment"
              link={payment.stripe.link}
              value="Link"
            />
          )}
          <DetailPair label="Amount" value={"$" + payment.amount.toString()} />
          <div className="md:col-span-2">
            <DetailPair
              label="Description"
              value={toTitleCase(payment.description)}
            />
          </div>

          <hr className="hr md:col-span-3" />

          {renderMenu()}

          <div className="md:col-span-3">
            <Notes
              collection="Payments"
              id={payment.id}
              notes={payment.notes}
              onComplete={fetchPaymentData}
            />
          </div>
        </div>
      )}
    </div>
  );
}
